<template>
	<div class="main">
		<div id="centerTop">
			<div id="show">
				<h1>微信产品</h1>
			</div>
			<div style="padding: 30px;">
				<div style="font-size: 24px; font-weight: bolder; padding-bottom: 20px;">安心开发</div>
				<div> <img src="./images/安心开发.950276ef.png" /> </div>
				<div style="font-size: 20px; font-weight: bold; padding-top: 20px;">
					简介：帮助客户一站式解决微信小程序注册开发问题。</div>
				<div style="font-size: 20px; font-weight: bold; padding-top: 20px; padding-bottom: 20px;">
					业务标签：电子商务、购物超市、政务民生、智能建站。</div>
				<div style="font-size: 24px; font-weight: bolder; padding-bottom: 20px; padding-top: 100px;">定制开发</div>
				<div><img src="./images/定制开发.815ae89c.png"></div>
				<div style="font-size: 20px; font-weight: bold; padding-top: 20px;">
					简介：根据客户要求定制开发微信小程序，公众号等。</div>
				<div style="font-size: 20px; font-weight: bold; padding-top: 20px; padding-bottom: 20px;">
					业务标签：政务民生、购物超市、电子商务、智能建站。</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		}
	}
</script>

<style scoped>
	* {
		margin: 0;
		padding: 0;
	}

	.main {
		width: 100%;
		height: 952px;
		background: #ecf9fa;
	}

	#centerTop {
		margin: 0 auto;
		width: 1100px;
		height: 952px;
	}

	#show {
		width: 100%;
		height: 118px;
	}

	h1 {
		text-align: center;
		color: #0862ae;
		padding-top: 20px;
		font-size: 56px;
	}
</style>