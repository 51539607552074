<template>
    <div id="div">
        <div id="one">
            <div id="left">
                <dl class="dl">
                    <dt class="dt">
                        <div class="iconbg">
                            <i class="iconfont icon-jixieshebei" ></i>
                        </div>
                    </dt>
                    <dd class="dd">
                        <div class="div1">机械设备</div>
                        <div class="div2">各种尺寸的电子屏，广告屏；电子书柜；视频监视器；音乐教室各种电子设备；多媒体教室各种电子设备；</div>
                    </dd>
                </dl>
                <dl>
                    <dt class="dt">
                        <div class="iconbg">
                            <i class="iconfont icon-cplus" ></i>
                        </div>
                    </dt>
                    <dd class="dd">
                        <div class="div1">计算机软件技术开发</div>
                        <div class="div2">根据用户需求可提供各种软件系统的开发；包括线上管理系统，微信小程序开发，手机APP开发，各种电子设备的二次定制开发；</div>
                    </dd>
                </dl>
                <dl>
                    <dt class="dt">
                        <div class="iconbg">
                            <i class="iconfont icon-zixun" ></i>
                        </div>
                    </dt>
                    <dd class="dd">
                        <div class="div1">技术咨询</div>
                        <div class="div2">提供关于电子硬件设备和软件相关内容的技术咨询；提供关于绩效管理相关内容的咨询。</div>
                    </dd>
                </dl>
                <dl>
                    <dt class="dt">
                        <div class="iconbg">
                            <i class="iconfont icon-fuwu" ></i>
                        </div>
                    </dt>
                    <dd class="dd">
                        <div class="div1">技术服务</div>
                        <div class="div2">提供关于软硬件技术的服务，包括开发服务和售后服务。</div>
                    </dd>
                </dl>
            </div>
        <div id="center"><img src="./images/96.jpg"></div>
        </div>
        <div id="two">
        <div id="center2"><img src="./images/97.jpg"></div>
            <div id="right">
                <dl class="dl1">
                    <dt class="dt">
                        <div class="iconbg">
                            <i class="iconfont icon-wujingongju" ></i>
                        </div>
                    </dt>
                    <dd class="dd">
                        <div class="div1">五金产品及电子产品批发零售</div>
                        <div class="div2">五金:建材五金、工具五金、家居五金；电子产品：户外大屏，室内广告屏等。</div>
                    </dd>
                </dl>
                <dl>
                    <dt class="dt">
                        <div class="iconbg">
                            <i class="iconfont icon-zhuanrangzhuangtai" ></i>
                        </div>
                    </dt>
                    <dd class="dd">
                        <div class="div1">技术转让及销售</div>
                        <div class="div2">本公司产品可进行技术转让及销售。</div>
                    </dd>
                </dl>
                <dl>
                    <dt class="dt">
                        <div class="iconbg">
                            <i class="iconfont icon-fuwu1" ></i>
                        </div>
                    </dt>
                    <dd class="dd">
                        <div class="div1">科技中介服务</div>
                        <div class="div2">本公司提供优质的科技中介服务。</div>
                    </dd>
                </dl>
                <dl>
                    <dt class="dt">
                        <div class="iconbg">
                            <i class="iconfont icon-maoyijinchukoulingshoupifa" ></i>
                        </div>
                    </dt>
                    <dd class="dd">
                        <div class="div1">国内一般贸易</div>
                        <div class="div2">其他:国内一般贸易。</div>
                    </dd>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
}
.dl{
    margin-top: 22px;
}
.dl1{
    margin-top: 40px;
}
#div{
    width: 1100px;
    height: 1344px;
    margin-top: 20px;
}
#one{
    width: 1100px;
    height: 672px;
}
#two{
    width: 1100px;
    height: 672px;
}
#left{
    width: 540px;
    height: 672px;
    float: left;
}

#center{
    width: 512px;
    height: 672px;
    float: right;
}
#center img{
    width: 512px;
    height: 327px;
    margin-top: 186px;
}
#center2{
    width: 512px;
    height: 672px;
    float: left;
}
#center2 img{
    width: 512px;
    height: 327px;
    margin-top: 186px;
}
#right{
    width: 540px;
    height: 672px;
    float: right;
}
dl{
    width: 540px;
    height: 150px;
}
.dt{
    width: 100px;
    height: 150px;
    float: left;
}
.dt .iconbg{
    width: 90px;
    height: 90px;
    background-image: linear-gradient(to bottom right, #7aefd5,#52afec, #2835ba);
    border-radius: 50%;
    text-align: center;
    margin-top: 39px;
}
.dt .iconbg i{
    display: block;
    font-size: 60px;
    padding-top: 13px;
    color: white;
}
.dd{
    width: 440px;
    height: 150px;
    float: left;
}
.dd .div1{
    width: 440px;
    height: 38px;
    padding-top: 50px;
    
    font-size: 24px;
    font-weight: bold;
    padding-left: 10px;
}
.dd .div2{
    width: 440px;
    height: 84px;
    border-top: 2px solid #3850f0;
    font-size: 12px;
    color: #a19a94;
}
</style>
