<template>
    <div class="div">
        <div id="centerTop">
        <div id="show"><h1>产品展示</h1></div>
        <ul>
            <li class="li1"><img src="./images/10.jpg"></li>
            <li class="li1"><img src="./images/14.jpg"></li>
            <li class="li1"><img src="./images/jianshiqi.jpg"></li>
            <li class="li1"><img src="./images/52.jpg"></li>
            <li class="li2"><img src="./images/daping.jpg"></li>
            <li class="li2"><img src="./images/13.jpg"></li>
            <li class="li2"><img src="./images/16.jpg"></li>
            <li class="li3" @click="$router.push({ path: '/cityCall/customer' })"><img src="./images/custmor.png"></li>
            <li class="li3" @click="$router.push({ path: '/cityCall/mechanic' })"><img src="./images/mechanic.png"></li>
            <li class="li3" @click="$router.push({ path: '/cityCall/rider' })"><img src="./images/rider.png"></li>
        </ul>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
}
.div{
    width: 100%;
    height: 952px;
    margin-top: 20px;
    background: #f8f9fb;
}
#centerTop{
    margin: 0 auto;
    width: 1100px;
    height: 952px;
}
#show{
    width: 100%;
    height: 118px;
    margin-top:10px;
}
h1{
    text-align: center;
    color: #0862ae;
    padding-top: 20px;
    font-size: 56px;
}
ul{
    width: 1100px;
    height: 833px;
}
li{
    list-style: none;
    -moz-box-shadow: 2px 2px 10px #909090;
   -webkit-box-shadow: 2px 2px 10px #909090;
   box-shadow:2px 2px 10px #909090;
   transition: 500ms;
}
li:hover{
    transform:  translate(0,-15px);
}
.li1{
    width: 250px;
    height: 230px;
    margin-right:12px;
    margin-left:12px;
    float: left;
    margin-top: 5px;
}
.li1 img{
    width: 250px;
    height: 230px;
}
.li2{
    width: 341px;
    height: 280px;
    margin-right:12px;
    margin-left:12px;
    float: left;
    margin-top: 20px;
}
.li2 img{
    width: 341px;
    height: 280px;
}

.li3{
    width: 240px;
    height: 240px;
    margin-right:12px;
    margin-left:12px;
    float: left;
    margin-top: 20px;
}
.li3 img{
    width: 240px;
    height: 240px;
}
</style>
