<template>
    <div>
        <div class="a">
            <img src="./images/hand.jpg">
            <div class="b">
                <div class="c">︽</div>
                <div class="d">
                    <div class="e">
                        &nbsp;&nbsp;&nbsp;&nbsp;恒臻科技（大连）有限责任公司是一家经营计算机软件技术开发、技术咨询、技术服务、技术转让及销售、科技中介服务、机械设备、五金产品及电子产品批发、零售、国内一般贸易等业务领域的高新技术产业。本公司由资深的业务顾问、项目经理、高级工程师组成。<br>
                        &nbsp;&nbsp;&nbsp;&nbsp;"真诚、务实、创新、服务"，是我们一贯遵循的企业文化理念！讲究先做人，后做事，要求全体员工踏踏实实、认认真真地做好自己的本职工作，我们的知识、思想和方法必须走在社会的前列，积极进取，建立以客户为中心的服务理念，不断提高企业对客户的服务意识。一流的产品、专业的技术、用心的服务，选择我们，让您舒心、安心、放心。
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{

        }
    }
}
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
}
 .a{
		width: 1100px;
		height: 620px;
		overflow: hidden;
		position: relative;
		background-color: aqua;
	}
    .a img{
        width: 1100px;
		height: 620px;
    }
	.b{
		width: 1100px;
		height: 680px;
		background:rgba(29, 20, 20, 0.7);
		transition: 0.5s;
		position: absolute;
		margin-top: 560px;
		top: 1px;
        color: white;
        font-size: 12px;
	}
	.a :hover.b{
		opacity: 1;
		transition: 0.5s;
		margin-top: -66px;
		font-size: 18px;
	}
    .c{
        font-size: 50px;
        text-align: center;
    }
    .d{
        background-image:url('./images/bg2.jpg');
        background-size: 1100px 620px;
        background-repeat: no-repeat;
        width:1100px;
        height:620px;
    }
    .e{
        width: 634px;
        height: 220px;
        margin: 0 auto;
        padding-top: 160px;
        font-size: 20px;
        font-weight: bold;
    }
</style>
