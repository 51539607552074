var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div"},[_c('div',{attrs:{"id":"centerTop"}},[_vm._m(0),_c('ul',[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('li',{staticClass:"li3",on:{"click":function($event){return _vm.$router.push({ path: '/cityCall/customer' })}}},[_c('img',{attrs:{"src":require("./images/custmor.png")}})]),_c('li',{staticClass:"li3",on:{"click":function($event){return _vm.$router.push({ path: '/cityCall/mechanic' })}}},[_c('img',{attrs:{"src":require("./images/mechanic.png")}})]),_c('li',{staticClass:"li3",on:{"click":function($event){return _vm.$router.push({ path: '/cityCall/rider' })}}},[_c('img',{attrs:{"src":require("./images/rider.png")}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"show"}},[_c('h1',[_vm._v("产品展示")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"li1"},[_c('img',{attrs:{"src":require("./images/10.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"li1"},[_c('img',{attrs:{"src":require("./images/14.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"li1"},[_c('img',{attrs:{"src":require("./images/jianshiqi.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"li1"},[_c('img',{attrs:{"src":require("./images/52.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"li2"},[_c('img',{attrs:{"src":require("./images/daping.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"li2"},[_c('img',{attrs:{"src":require("./images/13.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"li2"},[_c('img',{attrs:{"src":require("./images/16.jpg")}})])
}]

export { render, staticRenderFns }